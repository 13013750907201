<template>
  <router-view></router-view>
</template>

<script>
import emitter from '@/components/tools/emitter';

export default {
  name: 'Front Layout',
  data() {
    return {
      targetTime: null,
      currentTime: null,
      timezone: [
        {
          utc: 8,
          city: 'HK',
          country: 'Hong Kong',
        },
        {
          utc: 8,
          city: 'MO',
          country: 'Macau',
        },
        {
          utc: 8,
          city: 'BJ',
          country: 'China',
        },
        {
          utc: -7,
          city: 'SF',
          country: 'United States',
        },
        {
          utc: 3,
          city: 'TLL',
          country: 'Estonia',
        },
        {
          utc: 2,
          city: 'RM',
          country: 'Italy',
        },
        {
          utc: 0,
          city: 'CITYA',
          country: 'CITYA',
        },
      ],
    };
  },
  components: {
  },
  methods: {
    getCountry() {
      this.$http
        .get(`https://extreme-ip-lookup.com/json/?key=${process.env.VUE_APP_IP_API_KEY}`)
        .then((res) => {
          // console.log(res);
          this.currentCountry = res.data.country;
          emitter.emit('sendCity', this.currentCountry);
        })
        .catch((data, status) => {
          console.log(data, status);
        });
    },
  },
  mounted() {
    // this.targetTime = new Date(process.env.VUE_APP_LAUNCH_DATE).getTime();
    // this.currentTime = new Date().getTime();
    // if ((this.targetTime - this.currentTime) > 0) {
    //   this.$router.push('/coming-soon');
    // } else {
    //   this.getCountry();
    //   emitter.emit('emitTimezone', this.timezone);
    // }
    this.getCountry();
    emitter.emit('emitTimezone', this.timezone);
  },
};
</script>

<style lang="scss">
@import '../../assets/all';
</style>
